
import Vue from 'vue';
import { Swipe, SwipeItem, Tab, Tabs, Lazyload } from 'vant';
import 'vant/lib/index.css';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Lazyload);

import http from "@/utils/request";
import { defaultImgPath } from "@/common/constant.js";

export default {
  name: "productDetailH5",
  data() {
    return {
      current: 0, // 当前轮播
      defaultImgPath: defaultImgPath,
      active: 0, // 底部默认tab
      product: { // 产品信息
        orgName: ''
      },
      pictureList: [], // 轮播图列表
      specificationList: [] // 规格参数
    }
  },
  mounted() {
    let productId = this.getQueryString('id')
    http({
      method: "get",
      url: `/product/showProductDesc/${productId}.nd?loginType=CS`,
    }).then(res => {
      if(res.status == 200) {
        let data = res.data
        let { filedMap, pictures } = data
        this.product = data.product
        pictures.map(el => {
          this.pictureList.push({
            imgUrl: defaultImgPath + 'assets/new/images/product/' +this.product.materialGroup + '.jpg',
            pictureName: el.pictureName
          })
        })
        for(let key in filedMap) {
          const item = { label: key, child: [] }
          item.child = filedMap[key].map((item) => {
            const attrArr = item.split(':')
            const attrKey = attrArr[0].trim()
            const attrValue = attrArr[1].trim()
            return { attrKey, attrValue }
          })
          this.specificationList.push(item)
        }
      }
    })
  },
  methods: {
    handleChangeSwiper(index) {
      this.current = index
    },
    getQueryString(name, url) {
      let u = arguments[1] || window.location.href,
          reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
          r = u.substr(u.indexOf("?") + 1).match(reg);
      return r != null ? decodeURI(r[2]) : "";
    }
  }
}